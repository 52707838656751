// composable from SubscribeHelpers.js
import { PricingProductEnum } from "@finq/pricing/types"

export function useNavigateToSubscribe() {
  const router = useRouter()
  const localPath = useLocalePath()

  const navigateToSubscribe = (productId: PricingProductEnum, queryParams = {}) => {
    try {
      if (productId) {
        return router.push(
          localPath({
            name: "pricing-productId",
            params: { productId },
            ...(queryParams && { query: queryParams }),
          })
        )
      } else {
        return router.push(localPath({ name: "pricing", ...(queryParams && { query: queryParams }) }))
      }
    } catch (error) {
      console.error("[useNavigateToSubscribe]:", error)
    }
  }

  return {
    navigateToSubscribe,
  }
}
